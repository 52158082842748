import { FC } from 'react'

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'

import Button from '../Library/Button/Button'

interface OnboardingHelpItemProps {
  title: string
  description: string
  icon: JSX.Element
  onClick: () => void
}

const OnboardingHelpItem: FC<OnboardingHelpItemProps> = ({
  title,
  description,
  icon,
  onClick,
}) => {
  return (
    <Button
      variant="text"
      className="bg-white flex-1 text-start hover:shadow-lg hover:bg-white border border-solid border-gray-200 rounded-lg p-6 border flex flex-col items-start gap-4"
      onClick={onClick}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex w-full items-center gap-3">
          {icon}
          <p className="text-lg text-gray-700 flex items-center">{title}</p>
        </div>
        <ArrowTopRightOnSquareIcon className="text-indigo-600 h-6 w-6" />
      </div>
      <p className="text-sm text-gray-600 flex items-center">{description}</p>
    </Button>
  )
}

export default OnboardingHelpItem
